import React from 'react';
import RateChart from './RateChart';
import Select from 'react-select';

const LoanSummaryDetails = (props) => {
    const RBPObject = props.RBPObject;

    return (
        <>
            {props.showCompare ?
            <div className="col-md-10 mb-5">
            <div className="col-md-6">
                <div className="application-type-container col-md-10">
                    <div className="page-title">Application type</div>
                    <Select
                        className="select-pointer mx-2"
                        isSearchable={false}
                        defaultValue={RBPObject.segmentCatageries[0]}
                        options={RBPObject.segmentCatageries ? RBPObject.segmentCatageries : []}
                        value={props.applicationType}
                        onChange={(selectedApplication)=> {
                            props.setApplicationType(selectedApplication);
                        }}
                        getOptionLabel={(option) => {
                            return option.productDescription;
                        }
                        }
                        getOptionValue={(option) => {
                            return option.productName
                        }}
                    />
                </div>
            </div>
            </div> : ""}
        <div className="d-flex flex-row col-md-10 loan-summary">
            <div className="col-md-6">
                <div className="page-title">Loan Summary Details</div>
                <br />
                <div className="payment-checks">
                    <div className="column">
                        <div className="col-md-10">
                            <div
                                className="d-flex flex-column payment-tile px-4 py-3"
                                style={{ background: "#FFF", fontWeight: "600", }}
                            >
                                <div className="amount" id="totalamount">
                                    <span>{props.formatter.format(props.totalAmount)} </span>
                                </div>
                                <div className="description">
                                    <span>Total price</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 down-payment">
                            <div
                                className="d-flex flex-column payment-tile px-4 py-3"
                                style={{ background: "#FFF", fontWeight: "600" }}
                            >
                                <div className="amount d-flex flex-row justify-content-between">
                                    <span>{props.formatter.format(props.downPayment)}</span>
                                </div>
                                <div className="description">
                                    <span>Actual Down payment</span>
                                    {props.addOnRateFlag && props.downPayment < props.addOnSelectedDp && false ?
                                        <span style={{ color: "red" }}><br></br> Please input DP as per the segment required</span> : ""}
                                </div>
                                <div className="cta-wrap mt-2">
                                    <button className="btn-purple btn" onClick={(e) => props.handleShow(e, 'screen')}>Change amount</button>
                                    {props.showCompare && (<button className="btn-purple btn" onClick={() => props.setShowCompareModal(true)}>Compare</button>)}
                                </div>
                            </div>
                        </div>
                        {window.innerWidth <= 768 && props.downPayment !== 0 && RBPObject.status === "Y" && <RateChart downPayment={props.downPayment} RBPObject={props.RBPObject} eligibleSegments={props.eligibleSegments} formatter={props.formatter} totalAmount={props.totalAmount} />}
                        <div className="col-md-10">
                            <div
                                className="d-flex flex-column payment-tile px-4 py-3"
                                style={{ background: "#FFF", fontWeight: "600" }}
                            >
                                <div className="amount">
                                    <span>{props.formatter.format(props.loanableAmount)}</span>
                                </div>
                                <div className="description">
                                    <span>Loanable amount</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10"
                            style={{
                                display:
                                    RBPObject.status === "Y" && props.eligibleSegments.length !== 0 && props.addOnRateFlag
                                        ? ""
                                        : "none",
                            }}
                        >
                            <div
                                className="d-flex flex-column payment-tile px-4 py-3"
                                style={{ background: "#FFF", fontWeight: "600" }}
                            >
                                <div className="amount">
                                    <span>
                                        {props.currentSegmentObj
                                            ? props.currentSegmentObj?.addOnRate?.toFixed(2)
                                            : ""}
                                        %
                                    </span>
                                </div>
                                <div className="description">
                                    <span>Add-on rate</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 && props.eligibleSegments.length !== 0 && RBPObject.status === "Y" && <RateChart downPayment={props.downPayment} RBPObject={props.RBPObject} eligibleSegments={props.eligibleSegments} formatter={props.formatter} totalAmount={props.totalAmount} />}
        </div>
        </>
    );
};

export default LoanSummaryDetails;