import React, { useEffect, useState, useRef } from 'react';
import '../styles/login.css';
import { useNavigate } from "react-router-dom"
import {validation,getAccessToken,saltGeneration,setLoginSuccess} from "./Validation";
import { login } from '../RouteGuards/helper';
import Loader from './loader';
import axios from 'axios';

export const LoginPage = () => {

  const ref = useRef(null)

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [getInvalidUser, setInvalidUser] = useState();
  const [authToken, setAuthToken]=useState();
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [errors, setError] = useState({

  })

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });

  }

  let inputData = {
    userId: form.username,
    password: form.password
  };

  function onSubmitForm(e) {
    setInvalidUser('');
    sessionStorage.setItem("userId", form.username);
    e.preventDefault();
    login('success')
    setError(validation(form))
    if (Object.keys(errors).length === 0 && (form.username !== "" && form.password !== "")) {
      Login();
    }
  }


  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const isEmpty = (value) => (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
  const Login = () => {
    setIsLoading(true)

    localStorage.clear(); // Clears all data stored in local storage
    // You can also remove specific items using localStorage.removeItem('key');

    const URL = process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_API_LOGIN;
    //  console.log("Env:123", process.env.REACT_APP_URL_Add);
    //  console.log("mer:123", process.env);
    getAccessToken()
    .then(res => {
      let [encryptedPass,saltData,accessToken] = saltGeneration(inputData,res.data.access_token);
      const headers = {
        headers: {
          'Authorization': accessToken
        }
      };
      inputData.password = encryptedPass;
      axios.post(URL, inputData, headers)
      .then((res) => {
        //console.log(res)
        setInvalidUser('');
        login('success');
        if (res.data.status === "x05") {
          setLoginSuccess(res.data.data,saltData);
          setIsLoading(false)
          navigate("/Change-Password");
        }
        else if (res.data.status === "x03" || res.data.status === "x04" || res.data.status === "x07") {
          setIsLoading(false)
          setInvalidUser(res.data.message);

        } else if (res.data.status === "00") {
          const promoterDetails = setLoginSuccess(res.data.data,saltData);
          navigate('/merchantpage', {
              state:{promoterDetails:promoterDetails}
          });
          //call merchant api 
        
        } else {
          setIsLoading(false)
          setInvalidUser("We are unable to process your login request , please contact admin");
        }

      })
      .catch((err) => {
        setIsLoading(false)
        setInvalidUser("We are unable to process your login request , please contact admin");
      })
    })
    
    .catch(error =>
      console.error('getMerchantDetails REACT_APP_APIGEE_API_DM ', error));;

  }

  const getMerchantDetails = (promoterDetails) => {
    //apigee access token api call
    let access_token = ''
    // console.log('auth API token-', process.env.REACT_APP_APIGEE_OAUTH_EMAIL);
    // console.log('auth API token-', process.env.REACT_APP_APIGEE_OAUTH_PASSWORD);

    const username = process.env.REACT_APP_APIGEE_OAUTH_EMAIL;
    const password = process.env.REACT_APP_APIGEE_OAUTH_PASSWORD;
    
   // console.log('auth API token-', process.env.REACT_APP_APIGEE_OAUTH_AUTHORIZATION);
    axios.post(process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_APIGEE_OAUTH_API, {} , {
      headers: {
        "Content-Type": 'application/x-www-form-urlencoded',
        "Access-Control-Allow-Origin": "*"
      },
      auth:{
        "username" : username,
        "password" : password
      }
    })
      .then(res => {
        access_token = res.data.access_token       
       // console.log('auth API token-', JSON.stringify(res.data.access_token));
      //  getMerchantInfo(promoterDetails, access_token);

      })
      
      .catch(error =>
        console.error('getMerchantDetails REACT_APP_APIGEE_API_DM ', error));

  }

  


  useEffect(() => {
    console.log("Login page ");
    localStorage.removeItem("userToken")
    if (ref.current) return;
    ref.current = true;
  }, [errors])

  return (
    <div className="Login-page">
      {isLoading == true ? <Loader /> : ""}
      <div className="container">
        <div className="login-page">
          <div className="login-label pb-5">
            <div className="login-title"><p className='mb-0'>Welcome back!</p><p className='mb-0'>to <span className="text-color">Purple App</span></p></div>
            <span className="sub-title text-secondary">Login gamit ang access id at password</span>
          </div>
          <form onSubmit={onSubmitForm}>
            <p className='text-center text-danger invalid-user'>{getInvalidUser}</p>
            <div className="form-group">
              <label className="form-label">Username</label>
              <input type="text" className="form-control mb-2" name="username" value={form.username}
                onChange={handleChange} />
              {errors.username && <p style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>{errors.username}</p>}

            </div>
            <div className="form-group">
              <label className="form-label">Password</label>
              <input type={passwordShown ? "text" : "password"} className="form-control mb-2" name="password"
                value={form.password}
                onChange={handleChange}
              /><div className='toggle-eye'><i className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}
                id="togglePassword" onClick={togglePassword}></i></div>
              {errors.password && <p style={{ color: "red", fontSize: "13px", fontWeight: "bold" }}>{errors.password}</p>}
            </div>

            <div className="form-group text-end ">
              <a href="#" className="forgot-msg text-dark" data-toggle="modal" data-target="#exampleModal">Forgot Password?</a>
            </div>
            <div className="form-group">
              <button type="submit" disabled={isEmpty(inputData.userId) || isEmpty(inputData.password)} className="btn btn-dark w-100 form-control font-weight-bold">Login</button>
            </div>
          </form></div>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title" id="exampleModalLabel" >Forgot Password</h6>
              </div>
              <div className="modal-body">
                I-reset natin yan! Call your territory manager for a temporary password
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-dismiss="modal">ok</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}