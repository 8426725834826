import { useState } from 'react';
import axiosInstance from "../../js/axiosInstance";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
export const SMSPage = ({backToItemsPage}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const URL = process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_API_SEND_SMS;
  const allDetails = state.productDetails;
  var itemNames = allDetails.categories.map(function (el) { return el.categoryName; });
  const [formData, setFormData] = useState({ customerName: '', customerMobileNumber: '' });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const promoterSession = JSON.parse(sessionStorage.getItem("userDetails"))
  String.prototype.phoneNumber = function () {
    let inputValue = this;
    if (typeof inputValue !== 'undefined') {
      if (inputValue.startsWith('63')) {
        inputValue = inputValue;
      } else {
        inputValue = ('63' + inputValue.replace(/^6/, '').replace(/^63/, ''));
      }
      return inputValue
    }
    return "";
  }

  String.prototype.numberOnly = function () {
    let inputValue = this;
    return inputValue.replace(/[^0-9]*/g, "");
  }

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "customerMobileNumber") {
      value = value.numberOnly().phoneNumber().substring(0, 12);
    }
    let data = { ...formData, [name]: value };
    setFormData(data);
    const validationErrors = validate(data);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }
  };

  const validate = (data=false) => {
    setErrors({});
    const newErrors = {};
    let checkData = data ? data : formData;
    if (!checkData.customerName) newErrors.customerName = 'Name is required';
    if (!checkData.customerMobileNumber) {
      newErrors.customerMobileNumber = 'Number is required';
    } else if (!/^\d{12}$/.test(checkData.customerMobileNumber)) {
      newErrors.customerMobileNumber = 'Number is invalid';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    setSuccess(false);
    setError(null);

    try {
      let requestData = {
        "customerMobileNumber": formData.customerMobileNumber,
        "customerName": formData.customerName,
        "promoterUserId": promoterSession.userId,
        "merchantId": sessionStorage.getItem("merchantId"),
        "items": itemNames,
        "totalPrice": allDetails.total,
        "downPayment": allDetails.downPaymentAmount,
        "terms": allDetails.tenure,
        "payHingaFee": allDetails.vas.vasFee,
        "monthlyInstallment": allDetails.monthlyAmount,
        "promoterName": JSON.parse(sessionStorage.getItem("userDetails")).firstName,
        "promoterMobileNumber": promoterSession.mobile
      }
      if (state.RBPObject.status === "Y") {
        requestData.addonRate = state.currentSegment.addOnRate;
      }
      const response = await axiosInstance.post(URL, requestData);
      if (response.data.status?.code == "00") {
        setSuccess(true);
        setFormData({ customerName: '', customerMobileNumber: '' });
      } else {
        if(response.errors && response.errors.length > 0 && response.errors[0]) {
          setError(response.errors[0].message);
        } else {
          setError('Something went wrong. Please try again.')
        }
      }
      
      
    } catch (err) {
      if(err.response?.data?.errors && err.response?.data?.errors.length > 0 && err.response?.data?.errors[0]) {
        if(err.response?.data?.errors[0].message) {
          setError(err.response?.data?.errors[0].message);
        } 
        if(err.response?.data?.errors[0].code == "Security-1001") {
          navigate('/')
        }
      } else {
        setError('Something went wrong. Please try again.')
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="col-md-4  h-100 mb-3">
        <div className="SMS_form">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  name="customerName"
                  value={formData.customerName}
                  onChange={handleChange}
                />
                {errors.customerName && <span className='error-text'>{errors.customerName}</span>}
              </div>
              <div className="form-group">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control mb-2"
                  name="customerMobileNumber"
                  value={formData.customerMobileNumber}
                  onChange={handleChange}
                />
                {errors.customerMobileNumber && <span className='error-text'>{errors.customerMobileNumber}</span>}
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-dark w-100 form-control font-weight-bold" disabled={loading}>
                  {!loading ? (
                    <>
                      Send SMS
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </>
                  ) : (
                    <>
                      Submitting
                      <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </>
                  )
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        centered={true}
        alignment="center"
        show={error}
        backdrop="static"
        className='sendSms errorMessage'
        onHide={() => { setError(false); }}
      >
        <div className='d-flex'>
          <div className='iconWrapper h-100 m-3'><i className="fa fa-check" aria-hidden="true"></i></div>
          <div className='w-100'>
            <Modal.Header className="pb-2 ps-1">
              <Modal.Title>Oops!</Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-0 ps-1'>
              <div>
                <p className='content'>{error}</p>
              </div>
              <div className="d-flex justify-content-end gap-2">
                <button className="btn btn-dark form-control" onClick={() => { setError(false); }}>OK</button>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      <Modal
        centered={true}
        alignment="center"
        show={success}
        backdrop="static"
        className='sendSms successMessage'
        onHide={() => { setSuccess(false); backToItemsPage(); }}
      >
        <div className='d-flex'>
          <div className='iconWrapper h-100 m-3'><i className="fa fa-check" aria-hidden="true"></i></div>
          <div className='w-100'>
            <Modal.Header className="pb-2 ps-1">
              <Modal.Title>Loan Summary SMS sent.</Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-0 ps-1'>
              <div className="d-flex justify-content-end gap-2">
                <button className="btn btn-dark form-control" onClick={() => { setSuccess(false); backToItemsPage(); }}>OK</button>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default SMSPage;