import axios from 'axios';
import { getRefreshToken, getAccessToken, encryptRequestData } from '../pages/Validation';
import secureLocalStorage from "react-secure-storage";
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = secureLocalStorage.getItem("TDB-TOKEN");
    const kong  = secureLocalStorage.getItem("TDB-Auth");
    if (token) {
      config.headers['TDB-TOKEN'] = token;
      config.headers['Authorization'] = kong;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response } = error;
    if (response) {
      if (response.status === 401) {
        if (response.data.operation === "EXPIRED" || response.data.errors[0].code === "Security-1004") {
          const accessResponse = await getAccessToken();
          const kongToken = `Bearer ${accessResponse.data.access_token}`;
          secureLocalStorage.setItem("TDB-Auth", kongToken);
          const accessToken = secureLocalStorage.getItem("TDB-Auth");
          const secretToken = secureLocalStorage.getItem("TDB-TOKEN");
          const refreshTokenResponse = await getRefreshToken(accessToken, secretToken);
          const newToken = refreshTokenResponse.data.data.secret_token;
          let offset = secureLocalStorage.getItem("OFFSETS");
          offset.serverSalt = refreshTokenResponse.data.data.offset2;
          secureLocalStorage.setItem("OFFSETS",offset);
          secureLocalStorage.setItem("TDB-TOKEN", newToken);
          if("hash-request" in error.config.headers) {
            const requestData = JSON.parse(error.config.data);
            const hashedHeader   = encryptRequestData(requestData);
            error.config.headers = {...error.config.headers, ...hashedHeader};
            console.log(error.config.headers,hashedHeader);
          }
          error.config.headers['tdb-token'] = newToken;
          error.config.headers['Authorization'] = kongToken;
          return axios(error.config);
          
        }
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;