import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getVasRuleInfo } from "../js/vasRule.js";
import Products from "../data/prod/products.json";
import Tenure from "../data/prod/tenur.json";
import nonProducts from "../data/nonprod/products.json";
import nonTenure from "../data/nonprod/tenur.json";
import axiosInstance from "../js/axiosInstance";
import { encryptRequestData } from "./Validation";

const Calculations = (initState) => {

    const payhingaLinkText = process.env.REACT_APP_VAS_PAYHINGA_LINK_TEXT;
    const silZeroProduct = process.env.REACT_APP_SIL_ZERO_PRODUCT;
    const [downPayInterest, setdownPayInterest] = useState("");
    const [userDetails, setUserDetails] = useState(initState.userDetails);
    const [storeDetails, setStoreDetails] = useState(initState.promoterDetails);
    const maxLoanAmount = initState.promoterDetails.maxLoanAmount;
    const minLoanAmount = initState.promoterDetails.minLoanAmount;
    //const downPayInterest = initState.promoterDetails.downPayInterest;

    const [minEmiAmount, setMinEmiAmount] = useState(initState.promoterDetails.minEmiAmount);
    const [maxEmiAmount, setMaxEmiAmount] = useState(initState.promoterDetails.maxEmiAmount);
    const [addOnSelectedDp, setAddOnSelectedDp] = useState(0.00);
    const [addOnRateFlag, setAddOnRateFlag] = useState(false);
    const maxProductCount = initState.promoterDetails.maxProductCount;
    const [isLoading, setIsLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(initState.totalAmount ? initState.totalAmount : 0);
    const [vasSelectionValue, setVasSelectionValue] = useState(0); // default value 0 - withoutVAS
    const [downPayment, setDownPayment] = useState(0);
    const [increasedDPamount, setIncreasedDPamount] = useState(0);
    const [defaultdownPayment, setDefaultdownPayment] = useState(0);
    const [loanableAmount, setLoanableAmount] = useState(initState.loanableAmount ? initState.loanableAmount : 0.0);
    const [selected, setSelected] = useState(0);
    const [details, setDetails] = useState();
    const vasFeeTenureMap = {};
    //onclick for showing Payhing popup
    const [payhingaPopup, setPayhingaPopup] = useState(false);
    const [payhingaDisableButton, setPayhingaDisableButton] = useState(false);
    const [isInRange, setIsInRange] = useState(true);
    const [productDownpaymentArr, setProductDownpaymentArr] = useState([]);
    //state to handle all the RBP Object from backend
    const [RBPObject, setRBPObject] = useState(initState.RBPObject ? initState.RBPObject : {});
    const [currentSegmentObj, setCurrentSegmentObj] = useState({});
    const [productTenur, setProductTenur] = useState([]);
    const [eligibleSegments, setEligibleSegments] = useState([]);
    const [RBPMerchantLevel, setRBPMerchantLevel] = useState(initState.RBPObject ? initState.RBPObject : {});
    const [ProductChanged, setProductChanged] = useState(0);

    const payhingaPopupShow = () => {
        setPayhingaPopup(true);
    };

    const payhingaPopupClose = () => {
        setPayhingaPopup(false);
    };

    const [disableButton, setDisableButton] = useState(true);

    const [promotorConcernDetails, setPromotorConcernDetails] = useState({
        idPresent: "I confirm the ID presented is genuine.",
        physicalPresent: "I confirm the client is physically present.",
        assesmentCodeValue: "",
        confirmMsg: "Falsifying information will result in disciplinary action.",
    });

    const [vasDetails, setVasDetails] = useState(
        { typeId: 1, vasFee: 0.0, feeTenureId: 0 } //feeAmountId - for now not in the scope
    );

    const [VASFlag, setVASFlag] = useState(false);
    const [vasTenureList, setVasTenureList] = useState([]);
    const savedState = JSON.parse(localStorage.getItem("vasStateObj"));
    const [merchantStore, setMerchantStore] = useState(initState.merchantStore ? initState.merchantStore : []);
    const [isValidQR, setIsValidQR] = useState(false);
    //SIL ZERO

    const [isSilZero, setIsSilZero] = useState(false);
    
    //SIL APPLICATION TYPE
    const [applicationType, setApplicationType] = useState("");

    useEffect(() => {
        const isSILZERO = applicationType.productName === silZeroProduct;
        setIsSilZero( isSILZERO );
        RBPObject.segmentInfo = applicationType.segmentInfo;
        setRBPObject(RBPObject);
        if(slotName === "") {
            document.querySelectorAll('.category-name')?.forEach(function(element,index){
                let e = {};
                e.target = element;
                e.resetPurpose = true;
                element.value != '' && handleInputChange(e, index);
            })
        }
    }, [applicationType]);


    const [promoterDetails, setPromoterDetails] = useState([
        {
            id: "",
            name: "",
            purpleKey: "",
            merchantName: "",
            maxLoanAmount: "",
            minLoanAmount: "",
            downPayInterest: "",
            maxEmiAmount: "",
            minEmiAmount: "",
            maxProductCount: "",
        },
    ]);
    const [allItems, setAllItems] = useState(initState.allItems ? initState.allItems : [
        {
            categoryId: "",
            categoryName: "",
            brand: "",
            skuNo: "",
            amount: 0,
        },
    ]);

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
    });
    const formatterNodecimal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PHP",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const [loanDetails, setLoanDetails] = useState([
        { tenure: "", amount: 0, loanProductId: "", interest: "", term: "" },
    ]);
    const handleAddItems = () => {
        setDefaultdownPayment(0);
        setIncreasedDPamount(0);
        setDisableButton(true);
        const values = [...allItems];
        values.push({
            categoryId: "",
            categoryName: "",
            brand: "",
            skuNo: "",
            amount: 0,
        });
        setAllItems(values);
    };
    const enableQrGenerator = () => {
        const result = [
            ...new Set(
                allItems.flatMap((obj) =>
                    Object.keys(obj).filter(
                        (key) => obj[key] === 0 || obj[key] === "" || obj[key].trim() === ""
                    )
                )
            ),
        ];

        if (
            result.length === 0 &&
            (loanableAmount >= minLoanAmount) & (loanableAmount <= maxLoanAmount) &&
            loanDetails.amount > 0 &&
            selected != 0
        )
            setDisableButton(false);
        else setDisableButton(true);

        clearAssementDetails();
    };
    Array.prototype.hasMin = function(attrib) {
        return (this.length && this.reduce(function(prev, curr){ 
            return Number(prev[attrib]) < Number(curr[attrib]) ? prev : curr; 
        })) || null;
     }

    const isEmpty = (value) =>
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0);
    const handleInputChange = (e, index) => {
        let productDownpayment = downPayInterest;
        let productId = 0;
        setDefaultdownPayment(0);
        setIncreasedDPamount(0);
        setSelected(0);
        const { name, value } = e.target;
        const list = [...allItems];
        !( e.resetPurpose !== undefined && e.resetPurpose === true ) && setApplicationType(RBPObject.segmentCatageries[0]);
        
        if (e.target.name == "amount") {
            list[index][name] = e.target.value.slice(0, 6);
        } else {
            list[index][name] = value;
        }
        setAllItems(list);
        if (e.target.name == "categoryName") {
            const idx = e.target.selectedIndex;
            const option = e.target.querySelectorAll("option")[idx];
            const id = option.getAttribute("id");
            list[index]["categoryId"] = id;
            if(RBPObject?.status == "Y" && applicationType.productName !== silZeroProduct) {
                list[index]["priority"] = option.dataset.priority;
                const applicableValues = list.filter(o => !isNaN(+(o.priority)));
                const priorityObject = applicableValues.hasMin('priority');
                RBPObject.segmentInfo = applicationType.segmentInfo
                if(priorityObject !== null) {
                    let PrdItem = merchantStore.find(o => o.productId === priorityObject.categoryId);
                    if(PrdItem !== undefined) {
                        if(PrdItem.segmentInfo) {
                            RBPObject.segmentInfo = PrdItem.segmentInfo;
                        }
                        productDownpayment = Number(PrdItem.productDownpayment);
                        RBPObject.productId = Number(PrdItem.productId);
                        productId = PrdItem.productId;
                    }
                }
                setRBPObject(RBPObject);
                setProductChanged(Math.random());
                handleInterestrate(productDownpayment, productId);
            }
            setAllItems(list);
        }
        if (e.target.name != "categoryName") {
            handlecompute();
        }
        enableQrGenerator();
    };

    let compareDownpaymentArr = [];
    const handleInterestrate = (maxDownpayment,productId) => {
        // const interestRateValue = 
        //     e.target.options[e.target.selectedIndex].dataset.interestrate;
        // let updatedDownpaymentArr = [];
        // if(typeof(e.compareCalc) == 'undefined') {
        //     updatedDownpaymentArr = [...productDownpaymentArr];
        //     updatedDownpaymentArr[index] = interestRateValue;
        //     setProductDownpaymentArr(updatedDownpaymentArr);
        // } else {
        //     updatedDownpaymentArr = [...compareDownpaymentArr];
        //     updatedDownpaymentArr.push(interestRateValue);
        //     compareDownpaymentArr = updatedDownpaymentArr;
        // }
        // const maxDownpayment = Math.max(...updatedDownpaymentArr);
        //have to add the downpaymentinterest value with the max downpayment value
        let segmentDPInfo = 0;
        if(applicationType.productDpInfo && Object.keys(applicationType.productDpInfo).length && applicationType.productDpInfo[productId]) {
            segmentDPInfo = Number(applicationType.productDpInfo[productId]);
        }

        const downPayInterest =
        initState.promoterDetails.downPayInterest + maxDownpayment + segmentDPInfo;

        // const downPayInterest = typeof state.promoterDetails.downPayInterest !== "undefined" ? state.promoterDetails.downPayInterest + maxDownpayment : state.promoterDetails.downPayInterest + maxDownpayment;
        setdownPayInterest(downPayInterest);
        handlecompute(downPayInterest);
    };

    // this useEffect is to update the currentSegment value based on the downpayment value
    useEffect(() => {
        if (RBPObject?.status === "Y") {
            const updatedSegments = filterRBPSegments(totalAmount);
            CurrentSegmentFinderFromDPandTotalPrice(totalAmount, downPayment, updatedSegments);
        }
    }, [downPayment,loanableAmount,ProductChanged,RBPObject.segmentInfo]);

    //this below useEffect is to Check whether to enable or disable QR based on the current segment's loanable amount range
    useEffect(() => {
        if (RBPObject.status === "Y") {
            if (
                currentSegmentObj.minLoanAmount <= loanableAmount &&
                currentSegmentObj.maxLoanAmount >= loanableAmount
            ) {
                setIsValidQR(false);
            } else {
                setIsValidQR(true);
            }
        }
    }, [currentSegmentObj, downPayment]);

    // useEffect(() => {
    //     handlecompute();
    // }, [downPayInterest]);
    useEffect(() => {
        enableQrGenerator();
    }, [selected]);
    const handleDecimalNumbers = (e) => {
        if (e.key === ".") {
            e.preventDefault();
        }
    };

    const handlePasteDecimalNumbers = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]*/g, "");
    };

    const handlecompute = (downPay=false) => {
        const total = allItems.reduce((acc, curr) => {
            return acc + Number(curr.amount);
        }, 0);
        setTotalAmount(total);
        let downPayI = downPay !== false ? downPay : downPayInterest;
        const templonableamt = total - Math.round((downPayI / 100) * total);

        if (templonableamt < maxLoanAmount) {
            //debugger;
            setLoanableAmount(templonableamt);
            setDownPayment(Math.round((downPayI / 100) * total));
        } else {
            //debugger;
            setLoanableAmount(maxLoanAmount);
            setDownPayment(total - maxLoanAmount);
        }
    };

    let unWantedtenur = [];
    const [hideTenure,sethideTenure] = useState([]);

    const addProcessingFee = (loanAmt,tenure,monthDuration,returnFee=false) => {
        if(isSilZero === false) return loanAmt;
        let processFee = tenure.processingFee;
        let updatedAmt;
        if(tenure.processingfeeSlab === 'PERCENT') {
            processFee = processFee/100;
            updatedAmt = loanAmt * (1 + processFee)
            if(returnFee) return updatedAmt-loanAmt;
        } else {
            updatedAmt = loanAmt + (processFee)
            if(returnFee) return (updatedAmt-loanAmt)/monthDuration;
        }
        return updatedAmt;
    }

    const emiCalculator = (loanReqAmt, tenureInfo,max=false,min=false) => {
        let rateOfInt = typeof tenureInfo.mirPercent !== 'undefined' ? tenureInfo.mirPercent : Number(tenureInfo.interestRate);
        let monthDuration = typeof tenureInfo.tenure !== 'undefined' ? tenureInfo.tenure : Number(tenureInfo.tenur);
        if ((Array.isArray(rateOfInt) === false && rateOfInt !== 0.0) || (Array.isArray(rateOfInt) && rateOfInt[0] !== 0.0)) {
            let loanAmt = loanReqAmt < maxLoanAmount ? loanReqAmt : maxLoanAmount;
            let tenure = monthDuration;
            let denominator = Math.pow(1 + rateOfInt / 100, -tenure);
            let emi = (loanAmt * rateOfInt) / 100 / (1 - denominator);
            let r = Math.round(emi * 100);
            let f = r / 100.0;
            checkEMIAmountValidation(f, tenure, max, min );
            return f;
        } else {
            let loanAmt = loanReqAmt < maxLoanAmount ? loanReqAmt : maxLoanAmount;
            let emiAmt = Number(parseFloat(loanAmt/monthDuration).toFixed(2)); 
            let processFee = addProcessingFee(emiAmt,tenureInfo,monthDuration,'returnFEE');
            return emiAmt+processFee;
        }
    };

    function showElement(element) {
        if (element) {
            element.style.display = "block";
        }
    }

    function hideElement(element) {
        if (element) {
            element.style.display = "none";
        }
    }

    function checkEMIAmountValidation(emiAmount, tenure, max, min) {
        let tenureId = "tenure_"+slotName + tenure;
        let vasSupportTenureId = "tenure_vas_support_" + slotName + tenure;
        let vasTenureId = "tenure_vas_" + slotName + tenure;
        let tenureNumber = Array.isArray(tenure) ? tenure.join('') : tenure;
        let element = document.getElementById(tenureId);
        let element1 = document.getElementById(vasSupportTenureId);
        let element2 = document.getElementById(vasTenureId);
        //here i have to verify the emi amount range based min emi amount and max emi amount, Then i have to make it as display none in purticular div element
        let minAmountEMI = min ? min : minEmiAmount;
        let maxAmountEMI = max ? max : maxEmiAmount;
        if (
            (emiAmount > 0 && emiAmount < minAmountEMI) ||
            emiAmount >maxAmountEMI
        ) {
            (unWantedtenur.includes(tenureNumber) === false) && unWantedtenur.push(tenureNumber);
            // //fetch element by id and make it as display none
            // hideElement(element);
            // hideElement(element1);
            // hideElement(element2);
        } else {
            let indexF = unWantedtenur.indexOf(tenureNumber);
            unWantedtenur.splice(indexF, 1);
            // showElement(element);
            // showElement(element1);
            // showElement(element2);
        }
    }

    const addVASFee = (emi,vasInt,valueType,vasFee,loanAmt,monthDuration) => {
        if (valueType == "E") {
            vasFee = roundToTwoDecimalPlaces((emi.toFixed(2) * ((vasInt) / 100)) * 100 / 100.0);
            emi = emi + vasFee;
        } else if (valueType == "F") {
            emi = emi + vasInt;
        } else if (valueType == "L") {
            vasFee = Math.round(((loanAmt * vasInt) / 100 / 12) * 100) / 100.0;
            emi = emi + vasFee;
        }
        vasFeeTenureMap[monthDuration] = vasFee;
        return emi;
    }

    const vasEmiCalculator = (
        loanReqAmt,
        tenureInfo
    ) => {
        let vasInt = tenureInfo.feeValue;
        let monthDuration = tenureInfo.tenure;
        let valueType = tenureInfo.feeValueType;
        let normalRateOfInt = tenureInfo.interestRate;
        let ruleId = tenureInfo.feeTenureId;
        // if ((Array.isArray(normalRateOfInt) === false && normalRateOfInt !== 0.0) || (Array.isArray(normalRateOfInt) && normalRateOfInt[0] !== 0.0)) {
        if (normalRateOfInt !== 0.0 || isSilZero) {
            let loanAmt = loanReqAmt < maxLoanAmount ? loanReqAmt : maxLoanAmount;
            let vasFee = 0.0;
            let tenure = monthDuration;
            let emi = 0;
            if(isSilZero) {
                let SegmentTenureInfo = productTenur.find(o => o.tenur === monthDuration);
                if(SegmentTenureInfo !== undefined) {
                    let emiAmtWithoutFee = Number(parseFloat(loanAmt/tenure).toFixed(2));
                    let processEmiFee = addProcessingFee(emiAmtWithoutFee,SegmentTenureInfo.tenureDetail,tenure,'returnFee');
                    let vasEmiFee = addVASFee(emiAmtWithoutFee,vasInt,valueType,vasFee,loanAmt,monthDuration);
                    emi = processEmiFee+vasEmiFee
                }
            } else {
                let denominator = Math.pow(1 + normalRateOfInt / 100, -tenure);
                emi = (loanAmt * normalRateOfInt) / 100 / (1 - denominator);
                emi = addVASFee(emi,vasInt,valueType,vasFee,loanAmt,monthDuration);
            }
            // setVasInfo(vasFee, ruleId, setVasDetails, vasDetails);
            let r = Math.round(emi * 100);
            let f = r / 100.0;
            return f;
        } else {
            return loanReqAmt / monthDuration;
        }
    };

    const roundToTwoDecimalPlaces = (number) => {
        return Math.round(number * 100) / 100;
    };

    const show0 = (assesmentCode) => {
        document.getElementById("as0").style.background = "#785aff";
        document.getElementById("as1").style.background = "";
        document.getElementById("as2").style.background = "";
        document.getElementById("as0_lable").style.color = "white";
        document.getElementById("as1_lable").style.color = "black";
        document.getElementById("as2_lable").style.color = "black";
    };
    const show1 = (assesmentCode) => {
        document.getElementById("as0").style.background = "";
        document.getElementById("as1").style.background = "#785aff";
        document.getElementById("as2").style.background = "";
        document.getElementById("as0_lable").style.color = "black";
        document.getElementById("as1_lable").style.color = "white";
        document.getElementById("as2_lable").style.color = "black";
    };
    const show2 = (assesmentCode) => {
        document.getElementById("as0").style.background = "";
        document.getElementById("as1").style.background = "";
        document.getElementById("as2").style.background = "#785aff";
        document.getElementById("as0_lable").style.color = "black";
        document.getElementById("as1_lable").style.color = "black";
        document.getElementById("as2_lable").style.color = "white";
    };

    function setcheckboxValueOption2(flag) {
        setIsOption2(flag);
        if (isCheckedOption1 && flag && assesmentCodeFlag) {
            setdisableProceed(false);
        } else {
            setdisableProceed(true);
        }
    }

    function setcheckboxValueOption1(flag) {
        setIsOption1(flag);
        if (flag && isCheckedOption2 && assesmentCodeFlag) {
            setdisableProceed(false);
        } else {
            setdisableProceed(true);
        }
    }

    const setAssesmentValue = (assesmentCode) => {
        if (assesmentCode == 0) {
            setassesmentCodeFlag(true);
            show0(assesmentCode);
        } else if (assesmentCode == 1) {
            setassesmentCodeFlag(true);
            show1(assesmentCode);
        } else if (assesmentCode == 2) {
            setassesmentCodeFlag(true);
            show2(assesmentCode);
        }
        if (isCheckedOption1 && isCheckedOption2) {
            //<Button id="as_proceed" className="btn btn-primary p-2" style={{'background-color':'#707070',  color:'#e1d5d5', 'font-family':'inherit'}} disabled={disableProceed} onClick={proceedQRInfo}>
            setdisableProceed(false);
        } else {
            setdisableProceed(true);
        }
        setAssementDetails(assesmentCode);
    };

    const setVasSelectionId = (selectionId, vasFee) => {
        setVasDetails({
            typeId: vasDetails.typeId,
            vasFee: vasFee,
            feeTenureId: parseInt(selectionId),
            // feeAmountId: vasDetails.feeAmountId //based on amount logic
        });
    };
    const setAssementDetails = (assesmentCode) => {
        setPromotorConcernDetails({
            idPresent: promotorConcernDetails.idPresent,
            physicalPresent: promotorConcernDetails.physicalPresent,
            confirmMsg: promotorConcernDetails.confirmMsg,
            assesmentCodeValue: assesmentCode,
        });
    };

    const clearAssementDetails = () => {
        setPromotorConcernDetails({
            idPresent: promotorConcernDetails.idPresent,
            physicalPresent: promotorConcernDetails.physicalPresent,
            confirmMsg: promotorConcernDetails.confirmMsg,
            assesmentCodeValue: "",
        });
    };

    function getEMIDetails(e, vasFlagValue) {
        enableQrGenerator();
        setSelected(e.currentTarget.getAttribute("loanproductid"));
        const { tenure, amount, loanProductId, interest } = e.currentTarget;
        setVasSelectionValue(vasFlagValue);
        let processFee = e.currentTarget.getAttribute("processFee");
        if (vasFlagValue == 1) {
            var tenur = e.currentTarget.getAttribute("term");
            setVasSelectionId(
                e.currentTarget.getAttribute("loanproductid"),
                vasFeeTenureMap[tenur]
            );
            let TenureObject = productTenur.find(o => o.tenur === Number(tenur));
            if(TenureObject !== undefined) {
                processFee = TenureObject.processFee;
            }
            
        }
        

        setLoanDetails({
            term: e.currentTarget.getAttribute("term"),
            tenure: e.currentTarget.getAttribute("tenure"),
            amount: e.currentTarget.getAttribute("amount"),
            loanProductId: e.currentTarget.getAttribute("loanproductid"),
            interest: e.currentTarget.getAttribute("interestrate"),
            processFee: processFee,
        });
    }
    const navigate = useNavigate();

    const proceedQRInfo = () => {
        setdisableProceed(true);
        setSelected(0);
        PostItemDetails();
    };

    const PostItemDetails = () => {
        setIsLoading(true);
        details.assesmentCode = parseInt(promotorConcernDetails.assesmentCodeValue);
        details.vasFlag = vasSelectionValue
        if (RBPObject?.status === "Y") {
            details.segmentId = currentSegmentObj.segmentId;
            details.addOnRate = currentSegmentObj.addOnRate;
        }
        if (vasSelectionValue === 1) {
            details.vas = vasDetails;
        }

        const headers = {
            headers: encryptRequestData(details)
        };

        axiosInstance
            .post(URL, details, headers)
            .then((res) => {
                if (res != null) {
                    const productDetails = res.data.data;
                    productDetails.createdOn = new Date(res.data.data.createdOn);
                    productDetails.processFee = loanDetails.processFee
                    navigate("/Item-details", {
                        state: {
                            productDetails,
                            storeDetails:storeDetails,
                            userDetails,
                            promoterDetails,
                            token: initState.token,
                            currentSegment: currentSegmentObj,
                            RBPObject: RBPObject,
                            selectedValue: initState.selectedValue,
                            source_btn: source_btn,
                            isSilZero:isSilZero,
                        },
                    });
                    setIsLoading(false);
                }
            })
            .catch((err) => {
            });
    };

    // let authToken = state.token !== undefined ? state.token : sessionStorage.getItem("token");
    const options = {
        params: {
            channelId: "promoter",
        },
    };

    const apigeeDomain = process.env.REACT_APP_APIGEE_API_DM;
    let merchantId =
        initState.selectedValue !== undefined
            ? initState.selectedValue
            : sessionStorage.getItem("merchantId");
    const productlistURL =
        process.env.REACT_APP_APIGEE_PRODUCT_DETAILS + merchantId;

    useEffect(() => {
        setdownPayInterest(initState.promoterDetails.downPayInterest);
        if(Object.keys(RBPObject).length == 0 || merchantStore.length == 0) {
        //setdownPayInterest(typeof state.promoterDetails.downPayInterest !== "undefined" ? state.promoterDetails.downPayInterest : state.promoterDetails.downPayInterest);
        // let test = JSON.stringify(state);
        let selectedMerchant = initState.selectedValue;
        axiosInstance
            .get(apigeeDomain + productlistURL, options)
            .then((response) => {
                getSegmentInfo(selectedMerchant, options);
                setMerchantStore(response.data.data);

            })
            .catch((error) => {
                //if status code is 401 then redirect to login page
                // when token is expired or invalid then redirect to login page
                if (error.response.status === 401) {
                    navigate("/");
                }
            });
        }
    }, []);

    //fetching the segment details from backend
    const getSegmentInfo = (selectedMerchant, options) => {
        const segmentURL =
            process.env.REACT_APP_API_PROMOTOR_SEGMENT;
        options.params.merchantId = initState.selectedValue;
        axiosInstance
            .get(apigeeDomain + segmentURL, options)
            .then((response) => {
                //  let segmentInfo = [];
                let info = response.data.data;
                info.segmentCatageries.sort( function(a, b) {
                    return a.displayOrder - b.displayOrder;
                });
                info.segmentInfo = info.segmentCatageries[0].segmentInfo;
                setRBPMerchantLevel(JSON.parse(JSON.stringify(info)));
                setRBPObject(info);
            })
            .catch((error) => { });
    };

    useEffect(() => {
        //Vas rule API
        if (
            typeof savedState === "object" &&
            savedState !== null &&
            savedState.vasAPI &&
            savedState.VASFlag
        ) {
            // Successfully parsed the JSON string and it's an object
            setVASFlag(savedState.VASFlag);
            if (productTenur.length > 0 && savedState.vasTenureList.length > 0) {
                updateVasTenureObj(savedState.vasTenureList, productTenur);
            }

            vasDetails.typeId = savedState.typeId;
        } else {
            // getVasRuleInfo(setVASFlag, setVasTenureList, vasDetails, state);
            getVasRuleInfo(setVASFlag, vasDetails, initState)
                .then(vasTenureList => {
                    if (productTenur.length > 0 && vasTenureList.length > 0) {
                        updateVasTenureObj(vasTenureList, productTenur);
                    }
                })
                .catch(error => {
                    // Handle any errors that occurred during the getVasRuleInfo call
                    console.error("Error in getVasRuleInfo:", error);
                });
        }

        setPromoterDetails({
            id: initState.userDetails.userId,
            name: initState.userDetails.firstName,
            purpleKey: sessionStorage.getItem("merchantId"),
            merchantName: initState.promoterDetails.merchantName,
            maxLoanAmount: initState.promoterDetails.maxLoanAmount,
            minLoanAmount: initState.promoterDetails.minLoanAmount,
            maxEmiAmount: initState.promoterDetails.maxEmiAmount,
            minEmiAmount: initState.promoterDetails.minEmiAmount,
            downPayInterest: initState.promoterDetails.downPayInterest,
        });
        let TenureItem = currentSegmentObj?.tenureInfo?.find(o => o.tenure === Number(loanDetails.term));
        let mir = 0,apr=0;
        if(TenureItem !== undefined) {
            mir=TenureItem.mirPercent;
            apr=TenureItem.aprPercent;
        }

        let detailsObj = {
                            promoter: {
                                id: promoterDetails.id,
                                name: promoterDetails.name,
                                purpleKey: promoterDetails.purpleKey,
                            },
                            additionalInfo: {
                                mir:mir,
                                apr:apr,
                                addOnRate:currentSegmentObj.addOnRate,
                                productId:RBPObject.productId,
                                productType:applicationType.productName
                            },
                            categories: allItems,
                            downPaymentAmount: parseFloat(downPayment),
                            tenureId: loanDetails.loanProductId,
                            tenure: loanDetails.term,
                            monthlyAmount: parseFloat(loanDetails.amount),
                            monthlyInterestRate: loanDetails.interest,
                            loanableAmount:
                                loanableAmount < maxLoanAmount ? loanableAmount : maxLoanAmount,
                            total: totalAmount,
                            defaultDPamt: defaultdownPayment,
                            defaultDP: downPayInterest,
                            increasedDPamt: increasedDPamount,
                            assesmentCode: promotorConcernDetails.assesmentCodeValue,
                            idPresentConcern: promotorConcernDetails.idPresent,
                            physicalPresentConcern: promotorConcernDetails.physicalPresent,
                            vasFlag: vasSelectionValue,
                            vas: vasDetails,
                        };
        if(TenureItem !== undefined && isSilZero) {
            detailsObj.additionalInfo.processingFee = TenureItem.processingFee;
            detailsObj.additionalInfo.processingFeeSlab = TenureItem.processingfeeSlab;
        }
        setDetails(detailsObj);

        enableQrGenerator();
    }, [loanDetails, totalAmount, payhingaPopup, productTenur]);


    const URL = process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_API_PRODUCT;
    const productDetails =
        process.env.REACT_APP_SYSTEM === "PROD" ? Products : nonProducts;
    useEffect(() => {
        let actualProductTenur =
            process.env.REACT_APP_SYSTEM === "PROD" ? Tenure : nonTenure;
        setProductTenur(actualProductTenur);
        if (savedState && savedState.vasTenureList !== null && productTenur) {
            updateVasTenureObj(savedState.vasTenureList, productTenur);
        }
    }, []);
    const sizeOfProductTenureList = productTenur.length;
    const [disableApplynow, setDisableApplynow] = useState(true);
    const [showDownPay, setShowDownPay] = useState(false);
    const handleDownPayClose = () => setShowDownPay(false);
    const [tmpUpdatedLoanAmt, setTmpUpdatedLoanAmt] = useState(0);
    const [flagValue, setflagValue] = useState('screen');
    const handleShow = (e, source) => {
        setSelected(0);
        setDisableButton(true);
        setDisableApplynow(true);
        setShowDownPay(true);
    };
    const [disableProceed, setdisableProceed] = useState(true);
    const [assesmentCodeFlag, setassesmentCodeFlag] = useState(false);
    const [isCheckedOption1, setIsOption1] = useState(false);
    const [isCheckedOption2, setIsOption2] = useState(false);
    const [assesmentPopup, setAssesmentPopup] = useState(false);
    const assesmentPopupClose = () => setAssesmentPopup(false);
    const [source_btn, setSourceBtn] = useState('push-qr');
    const [resetDP, setResetDp] = useState(false);


    //onclick for generate QR code button
    const assesmentPopupShow = (src) => {
        setassesmentCodeFlag(false);
        setcheckboxValueOption2(false);
        setcheckboxValueOption1(false);
        setdisableProceed(true);
        //setSelected(0);
        setSourceBtn(src);

        if(src!=="push-qr") {
            details.processFee = loanDetails.processFee
            navigate("/Item-details", {
                state: {
                    productDetails:details,
                    userDetails,
                    storeDetails:storeDetails,
                    promoterDetails,
                    token: initState.token,
                    currentSegment: currentSegmentObj,
                    RBPObject: RBPObject,
                    selectedValue: initState.selectedValue,
                    source_btn: src,
                    isSilZero:isSilZero
                },
            });
        } else {
            setAssesmentPopup(true);
        }
    };

    function handleApplyNow(e,dpTemp=false) {
        return new Promise(resolve => {
            setShowDownPay(false);
            let tempAmount = tmpUpdatedLoanAmt;
            if(dpTemp) {
                tempAmount = dpTemp;
            }
            
            // if (tempAmount > 0) {
                //debugger;
                setDefaultdownPayment(Math.round((downPayInterest / 100) * totalAmount));
                setLoanableAmount(Number(totalAmount) - Number(tempAmount));
                setDownPayment(Number(tempAmount));
                setIncreasedDPamount(
                    Number(tempAmount) -
                    Math.round((downPayInterest / 100) * totalAmount)
                );
            // }
            resolve();
        });
    };

    const handledownpayChange = (e) => {
        if (e.target.value.length <= 6) {
            setSelected(0);
            setDisableButton(true);
            const currentDPAmount = e.target.value;
            const templonable = Number(totalAmount) - Number(currentDPAmount);
            let currentDpPercent = Number(currentDPAmount) / totalAmount * 100;
            let eligibleSegmentsLeastDpPercent = eligibleSegments?.length > 0 ? eligibleSegments[0].dpPercent : 0;
            let eligibleSegmentsMaxDpPercent = Math.max(...eligibleSegments.map(o => o.maxDpRange))
            if (
                downPayment != currentDPAmount &&
                templonable >= minLoanAmount &&
                Number(currentDPAmount) >=
                TotalPricePercentageFromMinDP(totalAmount, downPayInterest) &&
                templonable <= maxLoanAmount &&
                (RBPObject?.status === "Y" ? (currentDpPercent >= eligibleSegmentsLeastDpPercent && currentDpPercent <= eligibleSegmentsMaxDpPercent)
                    : true) &&
                currentDPAmount < totalAmount
            ) {
                setDisableApplynow(false);
                setTmpUpdatedLoanAmt(Number(currentDPAmount));
            } else {
                setTmpUpdatedLoanAmt(0);
                setDisableApplynow(true);
            }
            // enableQrGenerator();
        }
    };

    const checkTenureValid = (tenure,maxEmiAmtValue,minEmiAmountTemp,segmentLoanAmount=loanableAmount) => {
        const emiResult = emiCalculator(
            segmentLoanAmount,
            tenure,
            maxEmiAmtValue,
            minEmiAmountTemp
        );
        
       return emiResult >= minEmiAmountTemp && emiResult <= maxEmiAmtValue
    }

    const filterRBPSegments = (totalPrice,segments=false) => {
        let segmentsList = segments ? segments : RBPObject?.segmentInfo ? RBPObject?.segmentInfo : [];
        return segmentsList.filter((segment, index) => {
            let dpAmount = Math.ceil(totalAmount * (segment.dpPercent / 100));
            let [minEmiAmountTemp,maxEmiAmtValue] = getMinMaxAmount(segment,dpAmount);
            let segmentLoanAmount = (totalPrice - Math.ceil(totalPrice * (segment.dpPercent / 100)));
            const updatedTenures = segment.tenureInfo ? segment.tenureInfo.filter((tenure) => checkTenureValid(tenure,maxEmiAmtValue,minEmiAmountTemp,segmentLoanAmount)) : [];
            return !(updatedTenures.length === 0);
        })
    }

    const getMinMaxAmount = (segment,dpAmount=downPayment) => {
        let minEmiAmountTemp = segment.minEmiSlab === 'RATE' ? ((segment.minEmiAmount * dpAmount) / 100) : segment.minEmiAmount;
        // max Emi amount have to arraive segment config and segment max percentage(40-config) of down-payment for whichone is heigher
        let tempMaxEmiAmount = segment.maxEmiSlab === 'RATE' ? ((segment.maxEmiAmount * dpAmount) / 100) : segment.maxEmiAmount;
        let tempMaxEmiDp = (segment.maxDpPercentEmi * dpAmount) / 100;
        if (tempMaxEmiDp > tempMaxEmiAmount) {
            tempMaxEmiAmount = tempMaxEmiDp;
        }

        return [minEmiAmountTemp,tempMaxEmiAmount];
    }

    const CurrentSegmentFinderFromDPandTotalPrice = (totalPrice, downPayment, filteredSegments) => {
        let percentageOfDownPayment = (downPayment / totalPrice) * 100;
        percentageOfDownPayment = Math.floor(percentageOfDownPayment);
        let rejectedSegments = 0;
        filteredSegments.map((segment, index) => {
            if (
                percentageOfDownPayment >= segment.minDpRange &&
                percentageOfDownPayment <= segment.maxDpRange
                // ( (!isSilZero && Number(RBPObject.silZero) !== segment.segmentId ) || (isSilZero && Number(RBPObject.silZero) == segment.segmentId) )
            ) {
                unWantedtenur = [];
                setCurrentSegmentObj(segment);
                let [minEmiAmountTemp,maxEmiAmtValue] = getMinMaxAmount(segment);
                setMinEmiAmount(minEmiAmountTemp);
                setMaxEmiAmount(maxEmiAmtValue);
                const updatedProductTenur = segment.tenureInfo ? segment.tenureInfo.map((tenure, index) => {
                    const checkTenure = checkTenureValid(tenure,maxEmiAmtValue,minEmiAmountTemp);
                    if(checkTenure) {
                        return {
                            loanProductId: tenure.tenureId,
                            tenur: tenure.tenure,
                            interestRate: tenure.mirPercent,
                            description: tenure.tenure + " " + "months",
                            tenureDetail: tenure,
                            processFee: isSilZero ? addProcessingFee(loanableAmount,tenure,tenure.tenure,"returnFee") : 0
                        };
                    }
                    return null;       
                }).filter((item) => item !== null) : [];
                
                sethideTenure(unWantedtenur);
                if (RBPObject.status == "Y") {
                    setProductTenur(updatedProductTenur);
                }

                return downPayInterest !== '' && EligibleSegmentsFinderFromDPInterest(downPayInterest, RBPObject, loanableAmount, segment, updatedProductTenur, filteredSegments);
            } else {
                rejectedSegments++;
            }

        });

        if(rejectedSegments === filteredSegments.length) {
            setResetDp(filteredSegments);
        }
    };

    function updateVasTenureObj(vasObj, productObj) {
        const updatedVasTenureObj = vasObj
            .filter(vasData => productObj.some(product => product.tenur == vasData.tenure))
            .map(vasData => {
                const matchingProduct = productObj.find(product => product.tenur == vasData.tenure);
                if (matchingProduct) {
                    vasData.interestRate = matchingProduct.interestRate;
                }
                return vasData;
            });
        // Sort updatedVasTenureObj in descending order based on tenure
        updatedVasTenureObj.sort((a, b) => b.tenure - a.tenure);

        setVasTenureList(updatedVasTenureObj);
    }

    useEffect(() => {
        if (savedState && savedState.vasTenureList !== null && productTenur) {
            updateVasTenureObj(savedState.vasTenureList, productTenur);
        }
    }, [productTenur]);

    const setUpdatedDP = (eligibleSegments) => {
        let currentDpPercent = Math.ceil((downPayment / totalAmount) * 100);
        let eligibleSegmentsLeastDpPercent = eligibleSegments.length > 0 ? eligibleSegments[0].dpPercent : 0;
        if (currentDpPercent <= eligibleSegmentsLeastDpPercent) {
            setDownPayment(Math.ceil(totalAmount * (eligibleSegmentsLeastDpPercent / 100)));
            setLoanableAmount(totalAmount - Math.ceil(totalAmount * (eligibleSegmentsLeastDpPercent / 100)));
        }
    }

    useEffect(() => {
        let segments = filterRBPSegments(totalAmount,eligibleSegments);
        setUpdatedDP(segments);
    }, [eligibleSegments])

    useEffect(() => {
        if(resetDP) {
            setResetDp(false);
            setUpdatedDP(resetDP);
        }
    }, [resetDP])
    

    // This below function finds the eligible segments based on the 1)  downpayment interest value 2) loanable amount
    const EligibleSegmentsFinderFromDPInterest = (downPayInterest, RBPObject, loanableAmount, segmentObj, updatedProductTenur, filteredSegments) => {
        let actualDPPrecent = (downPayment / totalAmount) * 100;
        actualDPPrecent = Math.floor(actualDPPrecent);
        let tempArr0 = [];
        let tempArr1 = [];
        let tempArr2 = [];
        filteredSegments.forEach((segment, index) => {
            if (downPayInterest <= segment.maxDpRange) {
                segment.selected = false;
                tempArr0.push(segment);
            } else {
                segment.selected = false;
            }
        });
        if (updatedProductTenur.length === 0 && tempArr0.length > 0) {
            //have to remove object from the tempArr0 array based on segment id
            tempArr0.splice(tempArr0.findIndex((item) => item.segmentId === segmentObj.segmentId), 1);
        }
        setAddOnRateFlag(false);
        setAddOnSelectedDp(0.00);

        tempArr0.forEach((segment, index) => {
            let tempLoanableAmount = totalAmount - Math.ceil(totalAmount * (segment.dpPercent / 100));
            let adp = Math.floor(downPayment / totalAmount * 100);

            if (loanableAmount < segment.minLoanAmount) {
                return;
            }
            if (loanableAmount === maxLoanAmount) {
                if (adp <= segment.maxDpRange && tempLoanableAmount <= segment.maxLoanAmount) {
                    tempArr1.push(segment);
                }

            } else if ((tempLoanableAmount <= segment.maxLoanAmount)) {
                if (tempLoanableAmount >= minLoanAmount) {
                    tempArr1.push(segment);
                }
            }

            if (index === tempArr0.length - 1 && tempArr1.length === 0) {
                tempArr1.push(segment);
            }
        });

        tempArr1.forEach((segment, index) => {
            if (loanableAmount >= segment.minLoanAmount) {
                let actualDPPrecent = (downPayment / totalAmount) * 100;
                actualDPPrecent = Math.floor(actualDPPrecent);
                let tempLoanableAmount = totalAmount - Math.ceil(totalAmount * (segment.dpPercent / 100));
                if (actualDPPrecent <= segment.maxDpRange && tempLoanableAmount <= segment.maxLoanAmount) {
                    segment.isSelected = false;
                    tempArr2.push(segment);
                }
            }
        });
        //downpayment is more than segment max range
        if (tempArr2.length === 0 && tempArr1.length === 1) {
            tempArr2.push(tempArr1[0]);
        }
        tempArr2.sort((a, b) => b.addOnRate - a.addOnRate);
        tempArr2.forEach((obj, index) => {
            obj.isSelected = (index === 0); // Set isSelected to true for the first object, false for others
            if (index === 0) {
                setAddOnRateFlag(true);
                setAddOnSelectedDp(Math.ceil(totalAmount * (obj.dpPercent / 100)));
                setCurrentSegmentObj(obj);
            };// Set addOnRateFlag to enable disable 
        });

        tempArr1.forEach((obj, index) => {
            obj.isSelected = (obj.segmentId === tempArr2[0]?.segmentId);
        });
        setEligibleSegments(tempArr1);
    };

    const TotalPricePercentageFromMinDP = (totalPrice, percent) => {
        return Math.floor(totalPrice * (percent / 100));
    };    


    // Comparison Flow
    const [slotName,slotSelect] = useState('');
    const [showCompare, setShowCompare] = useState(false);
    useEffect(() => {
        let isRBP = ( Object.keys(RBPObject).length > 0 && RBPObject.status === 'Y' );
        setShowCompare(isRBP && !((eligibleSegments.length === 0 || downPayment < addOnSelectedDp)));
    }, [RBPObject.status, eligibleSegments, downPayment, addOnSelectedDp]);

    const [showCompareModal, setShowCompareModal] = useState(false);
    const [originalDownPay, setoriginalDownPay] = useState(0);
    const handleCompareModalClose = (e) => {
        setShowCompareModal(false);
    };
    return {
        formatter: formatter,
        totalAmount: totalAmount,
        downPayment: downPayment,
        addOnSelectedDp: addOnSelectedDp,
        showCompare: showCompare,
        setShowCompareModal: setShowCompareModal,
        loanableAmount: loanableAmount,
        addOnRateFlag: addOnRateFlag,
        currentSegmentObj: currentSegmentObj,
        RBPObject: RBPObject,
        eligibleSegments: eligibleSegments,
        handleShow: handleShow,
        payhingaPopup:payhingaPopup,
        payhingaPopupShow: payhingaPopupShow,
        payhingaPopupClose:payhingaPopupClose,
        payhingaDisableButton: payhingaDisableButton,
        payhingaLinkText: payhingaLinkText,
        productTenur: productTenur,
        selected: selected,
        emiCalculator: emiCalculator,
        vasTenureList: vasTenureList,
        VASFlag: VASFlag,
        vasEmiCalculator: vasEmiCalculator,
        getEMIDetails: getEMIDetails,
        showCompareModal: showCompareModal,
        handleCompareModalClose: handleCompareModalClose,
        setoriginalDownPay: setoriginalDownPay,
        promotorConcernDetails:promotorConcernDetails,
        allItems:allItems,
        merchantStore:merchantStore,
        handleInputChange:handleInputChange,
        handleDecimalNumbers:handleDecimalNumbers,
        handlePasteDecimalNumbers:handlePasteDecimalNumbers,
        showDownPay:showDownPay,
        handleDownPayClose:handleDownPayClose,
        handledownpayChange:handledownpayChange,
        handleApplyNow,handleApplyNow,
        disableApplynow:disableApplynow,
        handleAddItems:handleAddItems,
        disableButton:disableButton,
        isValidQR:isValidQR,
        assesmentPopupShow:assesmentPopupShow,
        assesmentPopup:assesmentPopup,
        assesmentPopupClose:assesmentPopupClose,
        setcheckboxValueOption1:setcheckboxValueOption1,
        setcheckboxValueOption2:setcheckboxValueOption2,
        setAssesmentValue :setAssesmentValue,
        proceedQRInfo:proceedQRInfo,
        disableProceed:disableProceed,
        setDownPayment:setDownPayment,
        setCurrentSegmentObj:setCurrentSegmentObj,
        slotSelect :slotSelect,
        setTmpUpdatedLoanAmt:setTmpUpdatedLoanAmt,
        setIsLoading:setIsLoading,
        setSelected:setSelected,
        isCheckedOption2:isCheckedOption2,
        isCheckedOption1:isCheckedOption1,
        hideTenure:hideTenure,
        minLoanAmount:minLoanAmount,
        isSilZero:isSilZero,
        setIsSilZero:setIsSilZero,
        applicationType,
        setApplicationType
    };
}

export default Calculations;